<template>
	<el-container style="margin:0px;padding:0px;">
		<el-header style="padding:0px;background-color: #545c64;color:#fff;">
			<el-row>
				<el-col :span="4">
					<div style="color:#fff;height:100%;font-size: 26px;text-align: center;line-height: 56px;">
						内部工作平台
					</div>
				</el-col>
				<el-col :span="20">
					<el-menu
						:default-active="activeIndex"
						class="el-menu-demo"
						mode="horizontal"
						background-color="#545c64"
						text-color="#fff"
						active-text-color="#ffd04b"
					  >
						<el-menu-item index="1">
							<router-link to="/">
								主页
							</router-link>
						</el-menu-item>
						<el-menu-item index="2">
							<router-link to="/task/index">
								任务
							</router-link>
						</el-menu-item>
						<el-menu-item index="3">
							<router-link to="/project/index">
								项目
							</router-link>
						</el-menu-item>
					</el-menu>
				</el-col>
			</el-row>
			
		</el-header>
		<el-main :style="{ height: windowHeight+ 'px' }">
			<el-scrollbar>
				<router-view/>
			</el-scrollbar>
		</el-main>
	</el-container>
</template>
<style>
	.el-menu-item a{text-decoration: none;}
	body,html{margin:0px;padding:0px;}
	.el-main{padding:10px;}
</style>
<script setup>
	import { ref,onMounted } from 'vue'
	const activeIndex=ref('1');
	let loginUserName = ref('未登录');
	var tmpname = localStorage.getItem('loginUserName');
	if(tmpname!=''){
		loginUserName.value=tmpname;
	}
	// const handleSelect = (key, keyPath) => {
	//   console.log(key, keyPath)
	// }
	
	let windowHeight=ref(1000);
	onMounted(()=>{
		windowHeight.value=window.innerHeight-78;
	})
</script>
import { createRouter, createWebHistory } from 'vue-router'
const routes = [
  {
    path: '/',
    name: '主页',
    component: () => import('../views/main/index.vue')
  },
  {
    path: '/project/index',
    name: '项目管理 - 主页',
    component: () => import('../views/project/index.vue')
  },
  {
    path: '/task/index',
    name: '任务看板 - 主页',
    component: () => import('../views/task/index.vue')
  },
  {
    path: '/login',
    name: '用户登录',
    component: () => import('../views/user/login.vue')
  },
  ,
  {
    path: '/test',
    name: 'test',
    component: () => import('../views/main/test.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router

import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import vuedraggable from 'vuedraggable'
import 'element-plus/dist/index.css'
import App from './App.vue'
import router from './router'
import store from './store'

const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

router.beforeEach((to, from, next) => {
  document.title =  '内部工作助手平台' // 设置页面标题，如果meta中没有设置标题则显示默认标题
  next()
})
app.use(store).use(ElementPlus).use(vuedraggable).use(router).mount('#app')
